import React, { useEffect, useRef, useState } from "react";
import { useLocation,useNavigate } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import config from "../config";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IndustryCard from "../components/IndustryCard";
import { Link } from "react-router-dom";

const Home: React.FC = () => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [dots, setDots] = useState<number[]>([]);
  const [cardsPerScreen, setCardsPerScreen] = useState(0);
  const [faqActiveIndex, setFaqActiveIndex] = useState<number | null>(null);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const slider = sliderRef.current;
    if (!slider) return;

    const handleResize = () => {
      const cards = slider.querySelectorAll<HTMLDivElement>(".feedback-card");
      const cardWidth = cards[0]?.offsetWidth || 0;
      const screenWidth = slider.offsetWidth;
      const totalCards = cards.length;
      const newCardsPerScreen = cardWidth > 0 ? Math.floor(screenWidth / cardWidth) : 0;
      const totalScreens = newCardsPerScreen > 0 ? Math.ceil(totalCards / newCardsPerScreen) : 0;

      setCardsPerScreen(newCardsPerScreen);
      setDots(Array.from({ length: totalScreens }, (_, i) => i));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const slider = sliderRef.current;
    if (!slider) return;

    const handleScroll = () => {
      const cardWidth = slider.querySelector<HTMLDivElement>(".feedback-card")?.offsetWidth || 0;
      if (cardWidth === 0 || cardsPerScreen === 0) return;

      const newCurrentIndex = Math.floor(slider.scrollLeft / (cardWidth * cardsPerScreen));
      setCurrentIndex(newCurrentIndex);
    };

    slider.addEventListener("scroll", handleScroll);

    return () => {
      slider.removeEventListener("scroll", handleScroll);
    };
  }, [cardsPerScreen]);

  const scrollToIndex = (index: number) => {
    const slider = sliderRef.current;
    if (!slider) return;

    const cardWidth = slider.querySelector<HTMLDivElement>(".feedback-card")?.offsetWidth || 0;
    if (cardWidth === 0 || cardsPerScreen === 0) return;

    slider.scrollTo({
      left: cardWidth * cardsPerScreen * index,
      behavior: "smooth",
    });
    setCurrentIndex(index);
  };

  const toggleFaq = (index: number) => {
    setFaqActiveIndex(faqActiveIndex === index ? null : index);
  };

  // Scroll to section based on URL hash
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.replace('#', ''));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [hash]);


  const navigate_toregister=() =>{
    const email = localStorage.getItem('email');
    if (email){
      navigate('/dashboard/subscriptions')
    }else{

      navigate('/register')
    }
  }

  const handleemailSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    
    try {
      const response = await axios.post(`${config.apiBaseUrl}/sample_email/`, { email });
      if (response.data.success) {
        toast.success('Sample list sent.');
      } else {
        setMessage(response.data.message || 'Something went wrong.');
      }
    } catch (error: any) {
      // Check if the error response exists from the backend
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.error || 'An unknown error occurred.';
        toast.error(`${errorMessage}`);
      } else {
        toast.error('An error occurred while sending the request.');
      }
    }
  };
  

  return (
    <div>
    <ToastContainer
  position="top-right"
  autoClose={5000}
  hideProgressBar={false}
  closeOnClick
  pauseOnFocusLoss
  draggable
  pauseOnHover
/>
{/* Home Section */}
<section className="home">
  <div className="home-content">
    <div className="text-content">
      <h1>Accurate Materials Price Lists for Home Service Professionals</h1>
      <p className="home-content-subtext">
        Got a question or need help with setup? Book a call with us!
      </p>
      <a 
        href="https://calendly.com/contact-accupricelists/question-or-help-with-accuprice" 
        target="_blank" 
        rel="noopener noreferrer"
        style={{
          display: "inline-block",
          backgroundColor: "orange",
          color: "white",
          padding: "0.75rem 1.5rem",
          fontSize: "1rem",
          fontWeight: "bold",
          borderRadius: "10px",
          textDecoration: "none",
          textAlign: "center",
          cursor: "pointer",
          marginBottom: "1rem",
        }}
      >
        Book A Call Here
      </a>
      <a
        href="https://accupricelists.com/dashboard"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          display: "inline-block",
          backgroundColor: "white", 
          color: "orange",
          padding: "0.75rem 1.5rem",
          fontSize: "1rem",
          fontWeight: "bold",
          borderRadius: "10px",
          textDecoration: "none",
          textAlign: "center",
          cursor: "pointer",
          marginBottom: "1rem",
        }}
      >
        Sign Up Now For Our Free Electrical Trial Until April 2025!
      </a>
    </div>
    <div className="video-content">
  <iframe
    width="560"
    height="315"
    src="https://www.youtube.com/embed/eN-uvEAri4o?autoplay=1&mute=1&rel=0&showinfo=0&controls=1&loop=1&playlist=eN-uvEAri4o"
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    referrerPolicy="strict-origin-when-cross-origin"
    allowFullScreen
  ></iframe>
</div>
  </div>
</section>


      {/* Compatibility Section */}
      <section className="compatibility-section">
        <h2 className="section-heading">Compatible Software</h2>
        <p className="section-subtitle">Our Accuprice Lists are compatible with any software that allows for bulk upload of items. Here are a few examples:</p>
        <div className="compatibility-logos">
          <img src="/images/procore.png" alt="Procore" className="logo" />
          <img src="/images/housecallpro.png" alt="Housecall Pro" className="logo" />
          <img src="/images/quickbooks.png" alt="QuickBooks" className="logo" />
          <img src="/images/servicetrade.png" alt="ServiceTrade" className="logo" />
          <img src="/images/jobber.png" alt="Jobber" className="logo" />
          <img src="/images/workiz.png" alt="Workiz" className="logo" />
        </div>
      </section>

      {/* Features Section */}
      <section className="features-section" id="feature">
        <h2 className="section-heading">Features You Need</h2>
        <p className="section-subtitle">
          Up to date pricelists you can download monthly and upload to your estimation and invoicing software. Have confidence that your products
          and materials are priced accurately and marked up exactly how much you want!
        </p>
        <div className="features-content">
          <img src="/images/features-latop.png" alt="Laptop with software" className="features-image" />
          <div className="features-details">
            <h3 className="features-subheading">YOUR ACCURATE CONTRACTOR PRICE MANAGER</h3>
            <ul className="features-list">
              <li><img src="/images/tick-with-circle.png" alt="tick" />Monthly updates to ensure current pricing accuracy.</li>
              <li><img src="/images/tick-with-circle.png" alt="tick" />Batch Import our materials list into QuickBooks and other software programs</li>
              <li><img src="/images/tick-with-circle.png" alt="tick" />Consistent product lists and descriptions from each monthly edition</li>
              <li><img src="/images/tick-with-circle.png" alt="tick" />Enter your desired markup for various price ranges</li>
              <li><img src="/images/tick-with-circle.png" alt="tick" />You have the option to include sale tax, Inventory options, and income type</li>
            </ul>
          </div>
        </div>
      </section>

   {/* Industries Section */}
<section className="industries-section">
  <h2 className="section-heading">Industries We Offer Pricelists For</h2>
  <div className="industries-content">
    {/* Electrical Industry Card */}
    <IndustryCard
      title={"Electrical"}
      imagePath={"/images/electrical.png"}
      link="/electrical"
    >
      <h3> Now Available!</h3>
      <p>The Accuprice Electrical Pricelist contains 1,000+ materials for all your residential and light commercial needs. All products have been selected and reviewed by expert electricians with decades of experience.</p>
      <ul>
        <li>300+ Conduit Fittings</li>
        <li>200+ Wires</li>
        <li>100+ Breakers</li>
        <li>50+ Breaker Boxes</li>
        <li>Indoor Junction Boxes, Outdoor Junction Boxes, and 4S Boxes</li>
        <li>Wiring Accessories</li>
        <li>Covers, Receptacles, and Switches</li>
        <li>And many more items!</li>
      </ul>
    </IndustryCard>

    {/* Lumber Industry Card */}
    <IndustryCard title={"Lumber"} imagePath={"/images/lumber.png"}>
      <h3>Coming Soon</h3>
      <p>We plan to have the Accuprice Lumber Pricelist available in 2025</p>
      <p>Subscribe to our newsletter to stay up to date for new catalog releases!</p>
    </IndustryCard>

    {/* Plumbing Industry Card */}
    <IndustryCard title={"Plumbing"} imagePath={"/images/plumbing.png"}>
      <h3>Coming Soon</h3>
      <p>We plan to have the Accuprice Plumbing Pricelist available in 2025</p>
      <p>Subscribe to our newsletter to stay up to date for new catalog releases!</p>
    </IndustryCard>
  </div>
</section>

      {/* Steps Section */}
      <section className="steps-section">
        <h1 className="section-heading">Effortless Pricing With <span className="accuprice-highlight">Accuprice</span></h1>
        <p className="section-subtitle">Follow these simple steps to get started with AccuPrice</p>
        <div className="accuprice-steps">
          <div className="step step-left">
            <div className="step-content">
              <div className="step-number">Step 1</div>
              <h2 className="step-title">Sign up</h2>
              <p className="step-description">Create an account to access our user portal.</p>
            </div>
          </div>
          <div className="step step-right">
            <div className="step-content">
              <div className="step-number">Step 2</div>
              <h2 className="step-title">Choose Your Price List</h2>
              <p className="step-description">Select the specific price list you need (electrical, plumbing, lumber, etc.).</p>
            </div>
          </div>
          <div className="step step-left">
            <div className="step-content">
              <div className="step-number">Step 3</div>
              <h2 className="step-title">Purchase or Subscribe</h2>
              <p className="step-description">
                Opt for a limited free edition or purchase the complete price list with price updates for 12 months.
              </p>
            </div>
          </div>
          <div className="step step-right">
            <div className="step-content">
              <div className="step-number">Step 4</div>
              <h2 className="step-title">Customize Your List</h2>
              <p className="step-description">
                Configure the price lists to include your specific profit markups and sales tax rates. You can set different markups for various price ranges to tailor the lists to your business needs.
              </p>
            </div>
          </div>
          <div className="step step-left">
            <div className="step-content">
              <div className="step-number">Step 5</div>
              <h2 className="step-title">Download the Excel File</h2>
              <p className="step-description">Download the customized price list as an Excel file, ready for use.</p>
            </div>
          </div>
          <div className="step step-right">
            <div className="step-content">
              <div className="step-number">Step 6</div>
              <h2 className="step-title">Integrate with Your Software</h2>
              <p className="step-description">Import the price list into QuickBooks or other software programs to streamline your estimates and invoices.</p>
            </div>
          </div>
          <div className="step step-left">
            <div className="step-content">
              <div className="step-number">Step 7</div>
              <h2 className="step-title">Generate Accurate Estimates and Invoices</h2>
              <p className="step-description">
                Use the updated price lists to produce accurate and professional estimates and invoices, enhancing your efficiency and credibility.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Video Section */}
      <section className="video-section">
        <h2 className="section-heading">Your First Steps with <span className="brand-name">Accu</span><span
            className="brand-name2">Price</span></h2>
        <div className="video-placeholder">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/2FCo43XutGM?si=zOFbFGsopvc2S8yO"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
      </section>

      {/* Price secrion */}
      <section className="price-section" id="pricing">
        <h2 className="section-heading">Pricing Plan</h2>
        <p className="section-subtitle">Choose the plan that fits your needs and start enjoying seamless service today.</p>
        <div className="price-section-wraper">
          <div className="price-cards-section">
            <div className="price-card price-card1">
              <div>
                <div className="price-dollar-icon">
                  <img className="dollar-icon" src="/images/dollar-icon.svg" alt="" />
                </div>
                <div className="price-amount-section">
                  <h4 className="price-amount-title">Free Edition</h4>
                  <h3 className="price-amount">$0</h3>
                  <h4>Normally <span className="price-amount-cut">$100</span></h4>
                </div>
                <div className="price-hr" ></div>
                <div className="price-includes-section">
                  <h4 className="price-includes-title">Includes:</h4>
                  <div className="inlcude-items-section">

                    <div className="inlucde-list-item">
                      <img className="tick-icon" src="/images/tick-icon.svg" alt="" />
                      <p className="inlcude-text">The top items needed to get started in your industry</p>
                    </div>
                    <div className="inlucde-list-item">
                      <img className="tick-icon" src="/images/tick-icon.svg" alt="" />
                      <p className="inlcude-text">Customizable Mark Up and Taxes</p>
                    </div>
                    <div className="inlucde-list-item">
                      <img className="tick-icon" src="/images/tick-icon.svg" alt="" />
                      <p className="inlcude-text">Access to free price updates for all items</p>
                    </div>

                  </div>
                </div>
              </div>
              <div className="price-button">
                <button className="price-button-move" onClick={navigate_toregister}>Get started</button>
              </div>
            </div>


            <div className="price-card price-card2">
              <div>
                <div className="price-dollar-icon">
                  <img className="dollar-icon" src="/images/dollar-icon.svg" alt="" />
                </div>
                <div className="price-amount-section">
                  <h4 className="price-amount-title">Annual Plan (12 Editions)</h4>
                  <h3 className="price-amount">$420</h3>
                  <h4>Normally <span className="price-amount-cut">$1000</span></h4>
                </div>
                <div className="price-hr"></div>
                <div className="price-includes-section">
                  <h4 className="price-includes-title">Includes:</h4>
                  <div className="inlcude-items-section">
                    <div className="inlucde-list-item">
                      <img className="tick-icon" src="/images/tick-icon.svg" alt=""/>
                      <p className="inlcude-text">Complete access to over 1000 items for your industry</p>
                    </div>
                     <div className="inlucde-list-item">
                      <img className="tick-icon" src="/images/tick-icon.svg" alt=""/>
                      <p className="inlcude-text">12 months of price updates for all of your items</p>
                    </div>
                    <div className="inlucde-list-item">
                      <img className="tick-icon" src="/images/tick-icon.svg" alt=""/>
                      <p className="inlcude-text">Customizable Mark Up and Taxes</p>
                    </div>
                    <div className="inlucde-list-item">
                      <img className="tick-icon" src="/images/tick-icon.svg" alt=""/>
                      <p className="inlcude-text">New Edition Notifications</p>
                    </div>
                    <div className="inlucde-list-item">
                      <img className="tick-icon" src="/images/tick-icon.svg" alt=""/>
                      <p className="inlcude-text">Try our new MONTHLY option for just $50!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="price-button">
                <button className="price-button-move" onClick={navigate_toregister}>Get started</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="benefits-section">
        <h1 className="section-heading">Benefits</h1>
        <p className="section-subtitle">Discover the Advantages of AccuPrice</p>
        <div className="benefits-container">
          <div className="benefit-item">
            <img src="/images/benefit1.png" alt="Accurate Pricing" className="benefit-icon" />
            <h2 className="benefit-title">Accurate and Up-to-Date Pricing</h2>
            <p className="benefit-description">
              Our updated price lists allow you to provide accuratly priced and detailed estimates that can allow you to line itemize your materials for nearly every job. 
            </p>
          </div>
          <div className="benefit-item">
            <img src="/images/benefit2.png" alt="Ease of Use" className="benefit-icon" />
            <h2 className="benefit-title">Ease of Use</h2>
            <p className="benefit-description">
              No more searching online and calling warehouses to get prices, with Accuprice our updated lists will be integrated directly into your estimating and invoicing software.
            </p>
          </div>
          <div className="benefit-item">
            <img src="/images/benefit3.png" alt="Comprehensive Coverage" className="benefit-icon" />
            <h2 className="benefit-title">Comprehensive Coverage</h2>
            <p className="benefit-description">
              Cover a comprehensive list of the top materials and products commonly used in electrical, plumbing, and other service industries.
            </p>
          </div>
          <div className="benefit-item">
            <img src="/images/benefit4.png" alt="Time-Saving" className="benefit-icon" />
            <h2 className="benefit-title">Time-Saving</h2>
            <p className="benefit-description">
              Reduce the time spent on price searching and manual updates, let us do the work of gathering an updated pricelist at your fingertips.
            </p>
          </div>
          <div className="benefit-item">
            <img src="/images/benefit5.png" alt="Improved Accuracy" className="benefit-icon" />
            <h2 className="benefit-title">Improved Accuracy in Estimates</h2>
            <p className="benefit-description">
              Generating accurate and itemized estimates and invoices is now easier than ever when you use your preffered invoicing software and Accuprice.
            </p>
          </div>
          <div className="benefit-item">
            <img src="/images/benefit6.png" alt="Customizable Markups" className="benefit-icon" />
            <h2 className="benefit-title">Customizable Profit Markups</h2>
            <p className="benefit-description">
              Dont rely on your technicians to mark up your prices - take control of your own profitability and tailor your mark ups for various price ranges.
            </p>
          </div>
        </div>
      </section>

      {/* Customer Feedback Section */}
      <section className="customer-feedback-container">
        <h2 className="section-heading">Our Customer Feedback</h2>
        <p className="section-subtitle">Don't take our word for it. Trust our customers</p>
        <div className="customer-feedback-slider" ref={sliderRef}>
          {/* Repeat feedback-card divs as necessary */}
          <div className="feedback-card">
            <div className="feedback-header">
              <div className="feedback-avatar">
                <img src="/images/avatar.png" alt="Avatar of Alex T." />
              </div>
              <div className="feedback-stars">★★★★☆</div>
            </div>
            <h3 className="feedback-name">Charles B.</h3>
            <p className="feedback-title">Electrical Contractor</p>
            <p className="feedback-text">It was a little expensive, but I appreciate the guys giving me a discount code. Works well with my Quickbooks where I make my estimates.</p>
          </div>
          <div className="feedback-card">
            <div className="feedback-header">
              <div className="feedback-avatar">
                <img src="/images/avatar.png" alt="Avatar of Alex T." />
              </div>
              <div className="feedback-stars">★★★★★</div>
            </div>
            <h3 className="feedback-name">John C.</h3>
            <p className="feedback-title">Electrical Contractor</p>
            <p className="feedback-text">It's surprisingly easy to use the prices sheet they give you. My old prices have been way off over the years compared to this list.</p>
          </div>
          <div className="feedback-card">
            <div className="feedback-header">
              <div className="feedback-avatar">
                <img src="/images/avatar.png" alt="Avatar of Alex T." />
              </div>
              <div className="feedback-stars">★★★★★</div>
            </div>
            <h3 className="feedback-name">Heston A.</h3>
            <p className="feedback-title">Electrician</p>
            <p className="feedback-text">Other than the prices being dialed, the names are really well written and easy to find when we are looking things up.</p>
          </div>
          <div className="feedback-card">
            <div className="feedback-header">
              <div className="feedback-avatar">
                <img src="/images/avatar.png" alt="Avatar of Alex T." />
              </div>
              <div className="feedback-stars">★★★★★</div>
            </div>
            <h3 className="feedback-name">Gabriel H.</h3>
            <p className="feedback-title">Handyman</p>
            <p className="feedback-text">I'm happy to pay another $500 for plumbing and other construction items when they are availible! They have the basics covered for residential electrical so far. </p>
          </div>
          <div className="feedback-card">
            <div className="feedback-header">
              <div className="feedback-avatar">
                <img src="/images/avatar.png" alt="Avatar of Alex T." />
              </div>
              <div className="feedback-stars">★★★★★</div>
            </div>
            <h3 className="feedback-name">Alex T.</h3>
            <p className="feedback-title">Electrical Contractor</p>
            <p className="feedback-text">Im no longer worry about outdated prices or manual errors.</p>
          </div>
          <div className="feedback-card">
            <div className="feedback-header">
              <div className="feedback-avatar">
                <img src="/images/avatar.png" alt="Avatar of Alex T." />
              </div>
              <div className="feedback-stars">★★★★★</div>
            </div>
            <h3 className="feedback-name">Bryan C.</h3>
            <p className="feedback-title">Electrical Contractor</p>
            <p className="feedback-text">We used to go into the hardware store every couple of years to update our own pricelist. Stoked to finally have found this.</p>
          </div>
          <div className="feedback-card">
            <div className="feedback-header">
              <div className="feedback-avatar">
                <img src="/images/avatar.png" alt="Avatar of Alex T." />
              </div>
              <div className="feedback-stars">★★★★★</div>
            </div>
            <h3 className="feedback-name">Nick F.</h3>
            <p className="feedback-title">Contractor</p>
            <p className="feedback-text">Bruce and Hunter spent a lot of time making these price lists and you can really tell when you go through them that they included everything</p>
          </div>
          <div className="feedback-card">
            <div className="feedback-header">
              <div className="feedback-avatar">
                <img src="/images/avatar.png" alt="Avatar of Alex T." />
              </div>
              <div className="feedback-stars">★★★★★</div>
            </div>
            <h3 className="feedback-name">Conrad F.</h3>
            <p className="feedback-title">Contractor</p>
            <p className="feedback-text">Electrial list is good. Waiting for the rest of the lists so I can have everything I need. </p>
          </div>
          {/* Add more feedback cards as needed */}
        </div>
        <div className="feedback-pagination">
          {dots.map((_, index) => (
            <span
              key={index}
              className={`dot ${currentIndex === index ? "active" : ""}`}
              onClick={() => scrollToIndex(index)}
            />
          ))}
        </div>
      </section>

      {/* FAQ Section */}
      <div className="faq-container">
        <h1 className="section-heading">FAQs</h1>
        <p className="section-subtitle">Here are some frequently asked questions about AccuPrice</p>
        {[
          { question: "How do I sign up and access the price lists?", answer: "Simply create an account on our website to access the user portal. Once logged in, you can choose the specific price list you need (electrical, plumbing, lumber, etc.) and either purchase a limited free edition or subscribe for monthly updates." },
          { question: "Can I customize the price lists to include my own profit markups and sales tax rates?", answer: "Yes, our price lists are fully customizable. You can configure profit markups for various price ranges and set your sales tax rates to ensure the lists match your specific business needs." },
          { question: "How often are the price lists updated?", answer: "The price lists are updated monthly, ensuring you always have access to the latest pricing data from large Big Box Stores in the United States." },
          { question: "What formats are the price lists available in?", answer: "The price lists are available as downloadable Excel files, which can be easily integrated into software programs like QuickBooks." },
          { question: "How do I integrate the price lists into QuickBooks or other software?", answer: "After downloading the price list, you can import the Excel file into QuickBooks or other compatible software. We provide step-by-step guides and how-to videos to assist with this process." },
          { question: "What if the products or materials I need are not listed?", answer: "Our price lists cover a wide range of general items desinged by electricians, plumbers, and contractors. If specific items are not listed, please contact our support team, and we will do our best to accommodate your needs in future updates." },
          { question: "How secure is my data on your platform?", answer: "We prioritize data security and employ industry-standard measures to protect your information. Your data is securely stored and never shared with third parties." },
          { question: "Are there any additional costs besides the subscription or purchase price?", answer: "No, there are no hidden fees. The subscription or purchase price covers all costs, including updates and customer support. These FAQs address common concerns and provide clear, helpful information to potential users, enhancing their understanding and confidence in your product." },
          { question: "How do I know which price list is right for my business?", answer: "Each price list is tailored for specific industries, such as electrical, plumbing, and plumber. Review the descriptions of each list to determine which one aligns with your business needs. Our support team is also available to help you choose the best option." },
          { question: "How often do I need to update the price lists in my software?", answer: "We recommend updating your price lists every quarter when new editions are released to ensure you have the most current pricing information." },
          { question: "What if I need to cancel my subscription?", answer: "You can cancel your subscription at any time through your user portal. If you cancel, you will still have access to the price lists for the remainder of the subscription period. We do not offer refunds once you have made an annual subscription. Please see the Terms and Conditions for more details. " },
          { question: "How long does it take to set up and start using the price lists?", answer: "The setup process is quick and straightforward. Once you sign up, you can access and download your price lists immediately. Customization and integration into your software should only take a few minutes with our guides and support." },
        ].map((faq, index) => (
          <div className="faq-item" key={index}>
            <button className={`faq-question ${faqActiveIndex === index ? "active" : ""}`} onClick={() => toggleFaq(index)}>
              {faq.question}
            </button>
            <div className="faq-answer" style={{ maxHeight: faqActiveIndex === index ? "none" : 0 }}>
              <p>{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Signup Footer Section */}
      <section className="signup-footer">
        <h1>Join AccuPrice Now!</h1>
        <p>Enter your email to stay up to date on our new price lists and updates</p>
        <form className="signup-form">
          <input type="email" placeholder="Enter your email"
          value={email} 
          onChange={(e) => setEmail(e.target.value)}   required />
          <button type="submit" onClick={handleemailSubmit}>Sign Up</button>
        </form>
      </section>
    </div>
  );
};

export default Home;

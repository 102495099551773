import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';
import ReactMarkdown from 'react-markdown';

interface BlogParagraph {
  text: string;
  order: number;
  image: string | null;
}

interface BlogPostData {
  title: string;
  author: string;
  category: string;
  intro: string;
  aurther_img: string | null;
  created_at: string;
  paragraphs: BlogParagraph[];
}

const BlogPost: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>(); // Get blog post ID from URL params
  const [blogPost, setBlogPost] = useState<BlogPostData | null>(null);

  // Function to navigate to the previous page
  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };

  // Fetch blog post details when the component mounts
  useEffect(() => {
    const fetchBlogPost = async () => {
      try {
        const response = await axios.get(`${config.apiBaseUrl}/blog_details/${id}/`);
        setBlogPost(response.data);
      } catch (error) {
        console.error('Failed to fetch blog post details', error);
      }
    };

    fetchBlogPost();
  }, [id]);

  useEffect(() => {
    if (blogPost) {
      document.title = blogPost.title;
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', blogPost.intro);
      } else {
        const meta = document.createElement('meta');
        meta.name = 'description';
        meta.content = blogPost.intro;
        document.head.appendChild(meta);
      }
    }
  }, [blogPost]);

  if (!blogPost) return <div>Loading...</div>;

  const isMarkdown = (text: string) => {
    // Simple heuristic to check if the text contains markdown syntax
    return /[#*_\-`]/.test(text);
  };

  return (
    <div>
      {/* Blog Post Container */}
      <div className="blog-post-container">
        <div className="hero-section">
          <div className="logo">
            <img src="/images2/accupricelogo2.svg" alt="AccuPrice Logo" />
          </div>
          <div className="tag-line">
            <p>Accurate Price Lists for Home Service Professionals</p>
          </div>
        </div>
        <div className="single-post">
          <div className="back-btn" onClick={handleGoBack}>
            <img src="/images2/chevron-left.svg" alt="Back" />
            <p>All Posts</p>
          </div>
          <div className="readinfo-group">
            <div className="category-time">
              <p className="category">{blogPost.category}</p>
              {/* <p className="time">5 min read</p> */}
            </div>
            <h1 className="single-post-title">{blogPost.title}</h1>
          </div>
          <div className="single-post-data">
            {blogPost.paragraphs.map((para, index) => (
              <div key={index} className="paragraph">
                {para.image ? (
                  <img src={`${config.imageBaseUrl}${para.image}`} alt={`Paragraph ${index}`} />
                ) : null}
                {isMarkdown(para.text) ? (
                  <ReactMarkdown children={para.text} />
                ) : (
                  <p>{para.text}</p>
                )}
              </div>
            ))}
            {/* <img src="/images2/PlaceholderSinglePost.svg" alt="Blog Post" />
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint
              commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit
              fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem.
              Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet
              aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum
              debitis quas aliquid. Reprehenderit, quia. Quo neque error repudiandae fuga? Ipsa laudantium
              molestias eos sapiente officiis modi at sunt excepturi expedita sint? Sed quibusdam recusandae alias
              error harum maxime adipisci amet laborum.</p> */}
            <div className="bottom-info">
              <div className="author-info">
                <div className="written-by">
                  <p>Written By</p>
                  <h6>{blogPost.author}</h6>
                </div>
                <div className="published-date">
                  <p>Published on</p>
                  <h6>{blogPost.created_at}</h6>
                </div>
              </div>

              <div className="social-links">
                <a className='social-link' href="#"><img className='social-icon' src="/images2/link-alt.svg" alt="Link" /></a>
                <a className='social-link' href="#"><img className='social-icon' src="/images2/LinkedIn-Black.svg" alt="LinkedIn" /></a>
                <a className='social-link' href="#"><img className='social-icon' src="/images2/x-black.svg" alt="X" /></a>
                <a className='social-link' href="#"><img className='social-icon' src="/images2/Facebook-black.svg" alt="Facebook" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPost;
